import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Layout from '../../../components/Layout';
import Button from '../../../components/Button';
import { getSeoConfig } from '../../../constants/defaultSeoProps';
import s from './DataAI.module.scss';

const propTypes = {
  details: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    contactUsContent: PropTypes.string.isRequired,
    dataAISections: PropTypes.array.isRequired,
    contactUsSummary: PropTypes.string.isRequired,
  }).isRequired,
  companyQuote: PropTypes.shape({
    footerDescription: PropTypes.shape({}).isRequired,
  }).isRequired,
};

const seoParams = {
  ogImagePath: '/og-images/dataAI.png',
  ogTitle: 'Data AI Services at Tech Holding',
  ogDesc:
    'At Tech Holding, we harness the transformative power of data, machine learning (ML), and AI to propel your business into the future.',
  ogPath: '/services/data-ai',
};

const updatedSeo = getSeoConfig(seoParams);

const DataAI = ({ details, companyQuote }) => {
  const { title, description, dataAISections, contactUsContent, contactUsSummary } = details;
  return (
    <Layout
      currentPage="/services/data-ai"
      seo={updatedSeo}
      title={updatedSeo.openGraph.title}
      footerDescription={companyQuote.footerDescription}
    >
      <div className={s.bgGray}>
        <div className={s.headerContainer} data-test="headerContainer">
          <div className={s.headerContent}>
            <div className={s.title}>
              <h2 data-test="title">{title}</h2>
            </div>
          </div>
        </div>
      </div>
      <div className={s.pageContainer} data-test="pageContainer">
        <div className={s.dataAIContent}>
          <div className={s.content}>
            <p>{description}</p>
          </div>
        </div>
        <div className={s.dataAISectionsContainer}>
          {dataAISections.map((dataAISection) => (
            <div className={s.dataAISections} key={dataAISection.title} data-test="dataAISections">
              <div className={s.content}>
                <h3>{dataAISection.title}</h3>
                <p>{dataAISection.summary}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={s.contactUsContent}>
        <div className={s.contactUsText}>
          <h3>{contactUsContent}</h3>
          <p className={s.contactUsSummary}>{contactUsSummary}</p>
        </div>
        <div className={s.button}>
          <Link to={`/contact`}>
            <Button theme="grey">Contact Us</Button>
          </Link>
        </div>
      </div>
    </Layout>
  );
};

DataAI.propTypes = propTypes;
export default DataAI;
