import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import DataAI from '../../containers/Services/DataAI';

const dataAISections = [
  {
    title: 'Strategy & Architecture',
    summary:
      'Developing a robust data and AI strategy requires a solid foundation. We design scalable architectures that integrate seamlessly with major data platforms, ensuring your data strategy aligns with business objectives. Our approach includes assessing current capabilities, defining future needs, and crafting a tailored blueprint that guides your data and AI transformation.',
  },
  {
    title: 'Data Engineering',
    summary:
      'Our data engineering services transform raw data into a clean, efficient format suitable for analysis and decision-making. Using best-in-class tools from leading Data Platforms, we build reliable data pipelines that support real-time and batch processing, enabling businesses to leverage their data assets effectively and efficiently.',
  },
  {
    title: 'MLOps',
    summary:
      'To operationalize machine learning models effectively, our MLOps services provide a framework for collaboration between data scientists and operations teams. We streamline the end-to-end machine learning lifecycle on platforms like AWS and GCP, from model development and testing to deployment and monitoring, ensuring your ML models deliver sustainable value.',
  },
  {
    title: 'AI and GenAI',
    summary:
      'We leverage AI to create intelligent solutions that enhance decision-making and automate complex processes. Our expertise extends to Generative AI (GenAI), where we utilize advanced techniques to generate new content and data predictions. With our strong partnerships in AWS, GCP, and Snowflake ecosystems, we empower businesses to innovate and stay ahead of the curve.',
  },
  {
    title: 'Data Migration',
    summary:
      'Migrating to a modern data platform can be daunting. Tech Holding specializes in seamless data migration to cloud environments such as AWS, GCP, or Snowflake. We ensure a secure, swift, and smooth transition with minimal downtime, helping you leverage the scalability and flexibility of cloud solutions.',
  },
  {
    title: 'Professional Service and Staffing Solutions',
    summary:
      'Whether you are looking for a turnkey project execution or need expert talent to augment your team, Tech Holding has the flexibility to meet your needs. Our professional services team can take complete ownership of your data, ML, and AI projects, or provide skilled professionals who integrate into your existing teams to enhance capacity and expertise.',
  },
];

const title = 'Data AI Services at Tech Holding';

const contactUsContent = 'Contact us to find out how our services can be tailored to your needs';
const contactUsSummary =
  'At Tech Holding, we are dedicated to enabling your success through cutting-edge solutions in data, ML, and AI. Leveraging our partnerships with leading Technology Partners, we ensure that every solution is built on a foundation of reliability, scalability, and innovation. Let us help you unlock the full potential of your data and technology investments.';

const description =
  'At Tech Holding, we harness the transformative power of data, machine learning (ML), and AI to propel your business into the future. Leveraging our strategic partnerships with leading platforms like AWS, GCP, and Snowflake, we offer a comprehensive suite of services that cater to every aspect of your data and AI journey. Whether you need full-scale professional services or specialized staffing solutions, our expert team is equipped to deliver excellence.';

const details = {
  title,
  description,
  dataAISections,
  contactUsContent,
  contactUsSummary,
};

const propTypes = {
  data: PropTypes.shape({
    contentfulCompanyQuote: PropTypes.shape({
      footerDescription: PropTypes.shape({}).isRequired,
    }),
  }).isRequired,
};

const DataAIPage = ({ data }) => (
  <DataAI details={details} companyQuote={data.contentfulCompanyQuote} />
);
DataAIPage.propTypes = propTypes;

export default DataAIPage;

export const query = graphql`
  query {
    contentfulCompanyQuote(label: { eq: "servicesAws" }) {
      footerDescription {
        json
      }
    }
  }
`;
